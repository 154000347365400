// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.2.3 T',
  restBaseUrl: 'https://testapi.fatturattiva.it/fatturattiva-rest/rest/',   
  //restBaseUrl: 'https://localhost:8080/fatturattiva-rest/rest/',
  rows: 20,
  apiKeyFatturAttiva: '12345678',
  // apiKeyFatturAttiva: 'WK9BS7Qayj4VSQAJHkES1q8UWBuyOyIiDe49rJOtARDhn6n6n80f5zxsbbHGWQwn'
  apikey: 'PoZRjsOVVbi2ZzlRUGiQtrVi1ZLwwvIQTa71HGxUlX0cURjdHO75TJEoGKmip9kP',
  restAutocompileUrl: 'https://testapi-autocompile.soluzione1.it/autocompile/rest/',
  configCompanyUrl: '/assets/data/company-dev.json'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
